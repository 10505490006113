import React, { useState, useEffect, useContext, useRef } from 'react'
import emailjs from '@emailjs/browser'
import useInput from '../../Hooks/useInputHook'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { countries } from '../../Engines/countriesEngine'
import { useParams } from 'react-router-dom'
import styles from '../../assets/css/sponsorPage.module.css'
import axios from 'axios'
import ChildStory from '../../Components/ChildStory'
import Overlay from '../../utils/Overlay'
const oragnisations = [
  { name: 'select Type', selected: true },
  { name: 'church' },
  { name: 'commercial enterprise' },
  { name: 'school' },
  { name: 'nonprofit' },
  { name: 'community' },
  { name: 'others' },
]
export default function SponsorAChild() {
  const form = useRef()
  const { childId } = useParams()
  const [childtoSponsor, setChildToSponsor] = useState({})
  const [isOrganisation, setIsOrganisation] = useState(false)
  const [firstName, setFirstName, clearFirstName] = useInput()
  const [lastName, setLastName, clearLastName] = useInput()
  const [email, setEmail, clearEmail] = useInput()
  const [organisation, setOrganisation, clearOrganisation] = useInput()
  const [phone, setPhone, clearPhone] = useInput()
  const [address1, setAddress1, clearAddress1] = useInput()
  const [address2, setAddress2, clearAddress2] = useInput()
  const [city, setCity, clearCity] = useInput()
  const [pronvice, setPronvice, clearPronvice] = useInput()
  const [selectCountry, setSeletCountry, clearSeletCountry] = useInput({
    name: 'select Country',
    code: 'selct',
    selected: true,
  })
  const [
    organisationType,
    setorganisationType,
    clearOrganisationType,
  ] = useInput(oragnisations[0])

  const [zipcode, setZipcode, clearZipcode] = useInput()
  const [openStorySponsor, setOpenStorySponsor] = useState(false)
  useEffect(() => {
    const fetchChild = async () => {
      let children = await axios.get(
        'https://www.sponsorachildplatform.jeslor.com/sponsorachildplatform/allone mumyuka foundation',
      )
      children = children.data[0].children
      setChildToSponsor(...children.filter((child) => child._id === childId))
    }
    fetchChild()
  }, [childId])

  const openChildStorySponsor = () => {
    setOpenStorySponsor(true)
  }

  const closeChildStoryHandle = () => {
    setOpenStorySponsor(false)
  }

  const handleIsOrganisation = () => {
    setIsOrganisation(!isOrganisation)
  }

  const sendSponsorInformationHandler = async (e) => {
    e.preventDefault()
    const sponsorData = {
      organisationName: organisation,
      organisationType: organisationType,
      sponsorfirstName: firstName,
      sponsorLastName: lastName,
      sponsorPhoneNumber: phone,
      sponsorEmail: email,
      sponsorAddress1: address1,
      sponsorAddress2: address2,
      pickedChild: childtoSponsor.firstName,
      childId: childtoSponsor._id,
      sponsorCity: city,
      sponsorProvince: pronvice,
      sponsorZipcode: zipcode,
      sponsorCountry: selectCountry,
    }

    await fetch(
      'https://www.sponsorachildplatform.jeslor.com/sponsorachildplatform/accounts/allone mumyuka foundation/sponsors',
      {
        body: JSON.stringify(sponsorData),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      },
    ).then(async (res) => {
      const data = await res.json()
      if (data.success === true) {
        clearFirstName()
        clearLastName()
        clearEmail()
        clearPhone()
        clearAddress1()
        clearAddress2()
        clearCity()
        clearPronvice()
        clearZipcode()
        clearSeletCountry()
        clearOrganisationType()
        clearOrganisation()
        if (data.childlevel === 'primary') {
          window.location.href =
            'https://www.paypal.com/donate/?hosted_button_id=QXRMK47W9XPAA'
        } else if (data.childlevel === 'secondary') {
          window.location.href =
            'https://www.paypal.com/donate/?hosted_button_id=QXRMK47W9XPAA'
        } else {
          window.location.href =
            'https://www.paypal.com/donate/?hosted_button_id=QXRMK47W9XPAA'
        }
      }
    })
  }

  const isOrganisationStyles = !isOrganisation
    ? `${styles.isOrganisationHidden}`
    : `${styles.isOrganisationShow}`

  return (
    <div className={styles.sponsorPage}>
      <p className={styles.sponsorHeadWelcome}>
        Thank you for choosing to sponsor:
      </p>

      <div className={styles.article}>
        <div className={styles.childDetails}>
          <Row>
            <Col lg="4">
              <div className={styles.childImageHolder}>
                <img
                  src={childtoSponsor.childPhoto}
                  alt={childtoSponsor.firstName}
                />
              </div>
            </Col>
            <Col lg="8">
              <div className={styles.childDetailsHolder}>
                <h3>
                  <span>{childtoSponsor.firstName}</span>{' '}
                  {childtoSponsor.lastName} {childtoSponsor.otherName}
                </h3>
                <Button
                  className={styles.childCardStory}
                  onClick={openChildStorySponsor}
                >
                  <FontAwesomeIcon icon={faBook} className={styles.storyIcon} />
                  {childtoSponsor.firstName}'s story
                </Button>
              </div>
              <h4 className={styles.sponsorshipPlan}>
                Child Sponsorship plan monthly *
              </h4>
              <div className={styles.monthlyAmount}>
                {childtoSponsor.level === 'Institute'
                  ? '$50 Monthly'
                  : childtoSponsor.level === 'Secondary'
                  ? '$42 Monthly'
                  : '$38 Monthly'}
              </div>
            </Col>
          </Row>
        </div>
        <form onSubmit={sendSponsorInformationHandler} ref={form}>
          <div className={styles.contactInformation}>
            <h4 className={styles.sectionHeader}>contact Information</h4>
            <Row className="py-3" style={{ textAlign: 'start' }}>
              <Col>
                <div className={styles.isOrganisation}>
                  <Form.Check
                    isValid={isOrganisation}
                    onClick={handleIsOrganisation}
                    type="switch"
                    id="custom-switch"
                    label="This is an organisation or company"
                  />
                </div>
              </Col>
            </Row>
            <div className={isOrganisationStyles}>
              <Row className="py-3">
                <Col className={styles.formInputs} lg="6">
                  <label>Organisation Name*</label>
                  <input
                    type="text"
                    value={organisation}
                    onChange={setOrganisation}
                    required={isOrganisation ? true : false}
                    name="form_organisationName"
                  />
                </Col>
                <Col className={styles.formInputs} lg="6">
                  <label htmlFor="favColor">Organisation Type*</label>
                  <select
                    id="favColor"
                    required={isOrganisation ? true : false}
                    onChange={setorganisationType}
                    name="form_organisationType"
                  >
                    {oragnisations.map((option) => (
                      <option
                        key={option.name}
                        value={option.name}
                        defaultValue={option.selected}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <h4>Primary Contact</h4>
            </div>

            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>First Name*</label>
                <input
                  type="text"
                  value={firstName}
                  onChange={setFirstName}
                  required
                  minLength="3"
                  name="from_firstName"
                />
              </Col>
              <Col className={styles.formInputs} lg="6">
                <label>Last Name*</label>
                <input
                  type="text"
                  value={lastName}
                  onChange={setLastName}
                  required
                  minLength="3"
                  name="form_lastName"
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs}>
                <label>Email address*</label>
                <input
                  type="email"
                  value={email}
                  onChange={setEmail}
                  required
                  name="form_email"
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label> Phone Number</label>
                <input
                  type="text"
                  value={phone}
                  onChange={setPhone}
                  name="form_phoneNumber"
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label> Child picked</label>
                <input
                  type="text"
                  value={childtoSponsor.firstName}
                  name="form_childPicked"
                />
              </Col>
            </Row>
          </div>

          <div className={styles.contactInformation}>
            <h4 className={styles.sectionHeader}>Mailing Address</h4>
            <Row className="py-3">
              <Col lg="6">
                <label htmlFor="country">country *</label>
                <select
                  id="country"
                  className={styles.smallerMobile}
                  onChange={setSeletCountry}
                  required
                  name="form_selectCountry"
                >
                  {countries.map((option) => (
                    <option
                      key={option.name}
                      value={option.name}
                      defaultValue={option.selected}
                    >
                      {option.name}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>address 1*</label>
                <input
                  type="text"
                  value={address1}
                  onChange={setAddress1}
                  required
                  name="form_address1"
                />
              </Col>
              <Col className={styles.formInputs} lg="6">
                <label>address 2</label>
                <input
                  type="text"
                  value={address2}
                  onChange={setAddress2}
                  name="form_address2"
                />
              </Col>
            </Row>

            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>City*</label>
                <input
                  type="text"
                  value={city}
                  onChange={setCity}
                  required
                  name="form_city"
                />
              </Col>
              <Col className={styles.formInputs} lg="6">
                <label>State/Pronvice*</label>
                <input
                  type="text"
                  value={pronvice}
                  onChange={setPronvice}
                  required
                  name="form_pronvice"
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>Zip/ Postal Code*</label>
                <input
                  type="text"
                  value={zipcode}
                  onChange={setZipcode}
                  required
                  name="form_zipcode"
                />
              </Col>
            </Row>
          </div>
          <button className={styles.sponsorButton}>Contine to Payment</button>
        </form>
      </div>
      {openStorySponsor && (
        <Overlay>
          <ChildStory
            child={childtoSponsor}
            closeChildStoryHandle={closeChildStoryHandle}
            hideSponsor={true}
          />
        </Overlay>
      )}
    </div>
  )
}
