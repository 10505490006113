import React from 'react'
import Children from '../Children'
import headerImage from '../../assets/images/sponsorheader.jpg'
import { Container, Row, Col } from 'react-bootstrap'
import styles from '../../assets/css/childrenPage.module.css'

export default function ChildrenPage({ setChildHandler }) {
  return (
    <div>
      <Container className={styles.childrenPageHeader}>
        <Row>
          <Col lg="4">
            <div className={styles.headerImageWrapper}>
              <img src={headerImage} alt="children header" />
            </div>
          </Col>
          <Col lg="8">
            <div className={styles.headerText}>
              <p>
                Your monthly support provides life-changing opportunities such
                as: an opportunity to attend or stay in school medical care,
                which often saves lives nourishing food mentoring and a safe
                environment through a local evangelical church and most
                important, opportunities to hear the gospel.
              </p>

              <p>
                Check out the stories of some of our kids and young adults
                waiting to be sponsored. Sign up today and make a lasting impact
                in their lives. The cost per month depends on the educational
                level of the child, not their age.
              </p>
              <ul>
                <li>PRIMARY LEVEL- $38 USD/month</li>
                <li>SECONDARY LEVEL- $42 USD/month</li>
                <li>POST SECONDARY TRAINING/EDUCATION- $50 USD/month</li>
              </ul>
              <p>
                Thank you for being the help to the children in need at AMF!
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Children setChildHandler={setChildHandler} />
    </div>
  )
}
