import React from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { FaRegHeart } from 'react-icons/fa'
import amfLogo from '../assets/images/amflogo.svg'

import styles from '../assets/css/navbar.module.css'

function NavScrollExample() {
  const scrollabeNav = [
    // "me - auto",
    'ms - auto',
    'my - 2',
    'my - lg - 0]',
    styles.scrollableLinks,
  ]
  return (
    <Navbar id="fixedNav" expand="lg" className={styles.navbarMain}>
      <Navbar.Brand href="https://www.allonemumyukafoundation.org/">
        <img src={amfLogo} alt="ffl logo" className={styles.brandLogo} />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="navbarScroll"
        className={styles.myToggler}
      />
      <Navbar.Collapse id="navbarScroll">
        <Nav className={scrollabeNav} navbarScroll>
          <Nav.Link
            className={styles.navbarLinks}
            href="https://www.allonemumyukafoundation.org/"
          >
            Home
          </Nav.Link>

          <Nav.Link
            className={styles.navbarLinks}
            href="https://www.allonemumyukafoundation.org/about.html"
          >
            who we are
          </Nav.Link>

          <Nav.Link
            className={styles.navbarLinks}
            href="https://www.allonemumyukafoundation.org/cause.html"
          >
            what we do
          </Nav.Link>
          <Nav.Link
            className={styles.navbarLinks}
            href="https://www.allonemumyukafoundation.org/getinvolved.html"
          >
            Get Involved
          </Nav.Link>
        </Nav>

        <a
          className={styles.donate}
          href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=3UJ8KTXEFZ9XC&currency_code=USD&source=url"
          variant="outline-success"
        >
          Donate
          <FaRegHeart className={styles.buttonIcon} />
        </a>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavScrollExample
